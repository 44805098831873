<template>
<!-- 同步大班课 -->
  <div class="addclass">
    <div class="content">
      <div class="cont-mid">
        <el-form ref="form" :rules="rules" :model="form" label-width="190px">
          <el-form-item label="课节名称：" prop="lessonsName">
            <el-input
              v-model="form.lessonsName"
              maxlength="100"
              show-word-limit
              style="width:555px"
            ></el-input>
          </el-form-item>
          <el-form-item label="上课时间：" required>
            <dateTimePikcer @dateTimePicker="handleTimeChange"></dateTimePikcer>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="13">
              <el-form-item label="授课老师：" :required="true" >
                <teacherSelect :requestType="'teacher'" :courseId="Number($route.params.id)" @teacherSelect="handleTeacherChange" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
               <teacherSelect :requestType="'assistant'" :courseId="Number($route.params.id)" @teacherSelect="handleTeacherChange" />
            </el-col>
          </el-row>
          
          <el-row :gutter="20">
            <el-col :span="8">
             <el-form-item label="台上学生："  :required="true" >
                <el-select v-model="form.maxUsers" placeholder="1v2" filterable>
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="(item, index) in studentAmount"
                      :key="index"
                    ></el-option>
                </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="台下学生："  prop="maxBackupUsers" label-width="100px">
                    <el-input
                        type="maxBackupUsers"
                        v-model.number="form.maxBackupUsers"
                    ></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="5">
                <el-form-item label=""  :required="true"  label-width="10px">
                    <el-checkbox v-model="form.autoOnStage">学生自动上台</el-checkbox>
                </el-form-item>
            </el-col>
          </el-row>
          

          <el-form-item label="直播课件："  :required="true" > 
            <uploadFile @uploadFile="handleFileChange" :name="'liveIdentifiers'" :sourceType=2 />
          </el-form-item>

          <el-form-item label="课节附件：">
            <uploadFile @uploadFile="handleFileChange" :name="'lessonIdentifiers'" :sourceType=2 />
          </el-form-item>

          <el-form-item label="回放视频：">
            <uploadFile @uploadFile="handleFileChange" :name="'videoIdentifiers'" :tips="{image:false,url:true}" :sourceType=1 />
          </el-form-item>

          <el-form-item class="el-form-item-btns">
            <el-button @click="$router.push(`/lesson/${$route.params.id}?breadNumber=4`)">取消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
          </el-form-item>

          <br />
          <br />
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newcourse",
  data() {
     var validateOffStage = (rule, value, callback) => {
       if(!value){
          callback();
       }
       setTimeout(() => {
         if (!Number.isInteger(value)) {
           callback(new Error('请输入数字值'));
         } else {
           if (value > 300) {
             callback(new Error('台下人数不能大于300'));
           } else if (value < 0) {
             callback(new Error('台下人数不能小于0'));
           } else {
             callback();
           }
         }
       }, 1000);
    }
    return {
      form: {
        maxUsers:2,
        autoOnStage:false,
      },
      rules: {
        lessonsName: [{ required: true, message: "请输入课节名称", trigger: "blur" }],
        video: [{ required: true }],
        maxBackupUsers:[{required: false ,type:'number',validator:validateOffStage}]
      },
      studentAmount: [
          {id:2,name:'1v2'},
          {id:3,name:'1v3'},
          {id:4,name:'1v4'},
          {id:5,name:'1v5'},
          {id:6,name:'1v6'},
          {id:7,name:'1v7'},
          {id:8,name:'1v8'},
          {id:9,name:'1v9'},
          {id:10,name:'1v10'},
          {id:11,name:'1v11'},
          {id:12,name:'1v12'}
      ]
    };
  },
  computed: {},
  components: {},
  methods: {
    handleTimeChange(data){
      // console.log(data);
      this.form.lessonsBeginTime = data.bTime
      this.form.lessonsEndTime = data.eTime
      // console.log(this.form);
    },
    handleTeacherChange(data){
      // console.log(data);
      let type = data.type === 'teacher' ? true : (data.type === 'assistant'? false: '')
      if(type) {
        this.form.teacherId = data.teacherId
        this.form.teacherRole = data.role
      } else {
        this.form.assistantId = data.teacherId
        this.form.assistantRole = data.role
      }
    },
    handleFileChange(data){
      // console.log(data);
      this.form[data.name] = data.fileMD5List;
    },
    async onSubmit() {
      if(this.form.lessonsName.trim() == '') {
        this.$message.error('课程名称不能为空');
        return;
      }
      this.$refs['form'].validate((valid)=>{
        // console.log(valid);
        if(valid) {
          this.commitForm();
        }
      })
    },
    async commitForm(){
      this.form.courseProjectId = this.$route.params.id;
      this.form.autoOnStage = this.form.autoOnStage ? 1 : 0
      // console.log(this.form);
      const loading = this.$loading(this.$store.state.user.loadingConfig);
      let resData = await this.$Api.Course.creatLesson(this.form);
      if (resData.code === 200) {
        loading.close();
        this.$notify({
          title: "成功",
          message: "创建成功",
          type: "success"
        });
        setTimeout(() => {
          this.$router.push(`/lesson/${this.$route.params.id}?breadNumber=4`);
        }, 500);
      } else {
        loading.close();
        this.$notify.error({
          title: "错误",
          message: resData.msg
        });
      }
    },
  },
  deactivated() {
    this.form = {
      maxUsers:2,
      autoOnStage:false,
    }
    this.$refs["form"].resetFields();
  },
  created() {}
};
</script>

<style lang="less"></style>
